import type { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import { useRouter } from 'next/router';
import type { FunctionComponent } from 'react';
import React from 'react';

import PublicLayout, {
  getPublicLayoutData,
} from 'src/components/layouts/PublicLayout/PublicLayout';
import AvisCritizr from 'src/components/shared/AvisCritizr/AvisCritizr';
import Breadcrumb from 'src/components/shared/Breadcrumb';
import Engagements from 'src/components/shared/Engagements/Engagements';
import MetaDescription from 'src/components/shared/Meta/MetaDescription';
import MetaRichSnippet from 'src/components/shared/Meta/MetaRichSnippet';
import MetaTitle from 'src/components/shared/Meta/MetaTitle';
import News from 'src/components/shared/News/News';
import Newsletter from 'src/components/shared/Newsletter/Newsletter';
import Partners from 'src/components/shared/Partenaires/Partners';
import Responsive from 'src/components/shared/Responsive/Responsive';
import Services from 'src/components/shared/Services/Services';

import { getBanners } from '../../../services/banners/getBanners';
import GarageInfos from './components/GarageInfos/GarageInfos';
import GarageInfosMobile from './components/GarageInfos/GarageInfosMobile/GarageInfosMobile';
import GaragePresentation from './components/GaragePresentation';
import SearchEngineGarage from './components/SearchEngineGarage/SearchEngineGarage';
import VehiclesAdGarage from './components/VehiclesAdGarage/VehiclesAdGarage';
import style from './GaragePage.module.scss';

import { getEngagements } from 'src/services/engagements/getNationalNews';
import { getFeedbacks, getFeedbackStats } from 'src/services/feedback';
import { getGarage } from 'src/services/garage/getGarage';
import { getGaragePage } from 'src/services/garage/getGaragePage';
import { getHomeGarageSlugs } from 'src/services/garage/getHomeGarageSlugs';
import { getNationalNews } from 'src/services/news/getNationalNews';
import { getPartners } from 'src/services/partners/getPartners';
import { getGarageServices } from 'src/services/prestation';
import { getServiceFamilies } from 'src/services/services/getServiceFamilies';
import { getOccasionVehicles } from 'src/services/vehicle/getOccasionVehicles';

import { useScrollToView } from 'src/utils/ReactUtils';
import { logAPIError } from 'src/utils/SentryUtils';

export const getStaticPaths = async () => {
  if (process.env.DISABLE_ISR !== 'true') {
    return {
      paths: [],
      fallback: 'blocking',
    };
  }

  const garageSlugs = await getHomeGarageSlugs();
  const paths = garageSlugs.map((vehicleSlug) => {
    return {
      params: {
        id: vehicleSlug.subDomain,
      },
    };
  });

  return {
    paths,
    fallback: false,
  };
};

export const getStaticProps = async ({ params }: GetStaticPropsContext) => {
  const garageSlug = typeof params?.id === 'string' ? params.id : '';

  const getGarageServiceFamiliesPromise = getGarageServices(
    garageSlug,
    true,
  ).catch((error) => {
    logAPIError(error);
    return [];
  });

  try {
    const garage = await getGarage(garageSlug);
    const [
      publicLayoutData,
      serviceFamilies,
      garageServiceFamilies,
      newsItems,
      engagements,
      feedbacks,
      feedbackStats,
      partners,
      vehicles,
      banners,
      garagePage,
    ] = await Promise.all([
      getPublicLayoutData({ garageSlug, garage }),
      getServiceFamilies({
        itemsPerPage: 5,
        idNetworkBase: garage.garIdNetwork,
      }),
      getGarageServiceFamiliesPromise,
      getNationalNews(),
      getEngagements(),
      getFeedbacks(garage.garIdNetwork),
      getFeedbackStats(garage.garIdNetwork),
      getPartners(),
      getOccasionVehicles(garage.garIdNetwork),
      getBanners(garage.garIdNetwork),
      getGaragePage(garage.garIdNetwork),
    ]);

    return {
      revalidate: process.env.DISABLE_ISR !== 'true' ? 360 : undefined,
      props: {
        garage,
        garageSlug,
        publicLayoutData,
        serviceFamilies,
        garageServiceFamilies,
        newsItems,
        engagements,
        feedbacks,
        feedbackStats,
        partners,
        banners,
        vehicles,
        garagePage,
      },
    };
  } catch (error) {
    logAPIError(error);

    return {
      notFound: true,
      props: {},
    };
  }
};

const HomeGaragePage: FunctionComponent<
  InferGetStaticPropsType<typeof getStaticProps>
> = ({
  garage,
  garageSlug,
  publicLayoutData,
  serviceFamilies,
  garageServiceFamilies,
  newsItems,
  engagements,
  feedbacks,
  feedbackStats,
  partners,
  vehicles,
  banners,
  garagePage,
}) => {
  const router = useRouter();
  const { scrollPointRef, handleOnClick: handleSeeMoreClick } =
    useScrollToView();

  if (
    router.isFallback ||
    !garage ||
    !garageSlug ||
    !publicLayoutData ||
    !serviceFamilies ||
    !garageServiceFamilies ||
    !newsItems ||
    !engagements ||
    !feedbacks ||
    !feedbackStats ||
    !partners ||
    !vehicles ||
    !banners ||
    !garagePage
  ) {
    return null;
  }

  return (
    <PublicLayout data={publicLayoutData}>
      <MetaTitle
        title={
          garage.seoTitle || `${garage?.name} - Entretien et réparation auto`
        }
      />
      <MetaDescription
        description={`${garage?.name} - ${garage?.companyPresentation}.`}
      />
      <MetaRichSnippet content={garagePage.structuredData} />
      <Responsive is={'mobile'}>
        <Breadcrumb
          className={style.breadcrumb}
          breadcrumbItems={garagePage.breadcrumb}
        />
      </Responsive>
      <GarageInfos
        feedbackStats={feedbackStats}
        banners={banners}
        garage={garage}
        slug={garageSlug}
        handleSeeMoreClick={handleSeeMoreClick}
        breadcrumbItems={garagePage.breadcrumb}
      />
      {garage?.hasDevis && <SearchEngineGarage garage={garage} />}
      {garage.hasVehicle && (
        <VehiclesAdGarage
          vehicles={vehicles}
          idBaseNetwork={garage.garIdNetwork}
        />
      )}
      <GarageInfosMobile garage={garage} />
      {garageServiceFamilies.length > 0 && (
        <Services
          carServiceBlocks={garageServiceFamilies}
          garage={garage}
          garageSlug={garageSlug}
          title="CHOISISSEZ VOTRE RÉPARATION MOINS CHÈRE AVEC UN GARAGE AUTO AD"
        />
      )}
      <News
        newsItems={newsItems}
        dataTestId="garage_news"
        title={
          "PROFITEZ DE NOS OFFRES ET PROMOTIONS SUR L'ENTRETIEN DE VOTRE VOITURE  "
        }
      />
      <Engagements
        url="nos-engagements"
        maxLengthDesc={55}
        engagements={engagements}
        dataTestId="garage_engagements"
        title={'NOS ENGAGEMENTS POUR VOTRE VOITURE'}
        titleLevel={'h2'}
        itemTitleLevel={'h3'}
      />
      {!!feedbacks.length && (
        <AvisCritizr
          idBaseNetwork={garage?.garIdNetwork}
          feedbacks={feedbacks}
          averageRating={feedbackStats.reviewNote}
          totalRatings={feedbackStats.countReview}
          title={'NOS CLIENTS RECOMMANDENT LES GARAGES ET CARROSSERIES AD'}
          titleLevel={'h2'}
        />
      )}
      <Partners
        redirectUrl={'/partenaires'}
        partners={partners}
        dataTestId="garage_partners"
      />
      <Responsive isNot="mobile">
        <GaragePresentation
          companyPresentation={garage.companyPresentation}
          pageContent={garage.pageContent}
          scrollPointRef={scrollPointRef}
          typeEnseigne={garage.typeEnseigne}
          city={garage.city}
          zipCode={garage.zipCode}
        />
      </Responsive>
      <Newsletter dataTestId="garage_newsletter" />
    </PublicLayout>
  );
};

export default HomeGaragePage;
